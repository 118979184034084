<template>
  <i>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9999 1.92C11.8242 1.92 1.91992 11.8243 1.91992 24C1.91992 36.1757 11.8242 46.08 23.9999 46.08C36.1756 46.08 46.0799 36.1757 46.0799 24C46.0799 11.8243 36.1756 1.92 23.9999 1.92ZM12.4799 15.36H35.5199V16.4981C33.3501 18.3815 25.9238 24.8281 25.2843 25.3856C24.759 25.842 24.2423 25.92 23.9999 25.92C23.7586 25.92 23.2406 25.8434 22.7155 25.3856L22.7137 25.3837C22.0976 24.8482 15.2071 18.8928 12.4799 16.5356V15.36ZM35.5199 19.0425V28.98L29.503 24.2662C31.8349 22.2414 33.4572 20.833 35.5199 19.0425ZM12.4799 19.0744C14.7455 21.0329 16.2152 22.3044 18.4837 24.2662L12.4799 28.935V19.0744ZM19.9649 25.545C20.3288 25.8599 21.3691 26.7596 21.4537 26.8331C22.3667 27.6291 23.3466 27.84 23.9999 27.84C24.6519 27.84 25.6321 27.6308 26.5462 26.835V26.8331C26.6347 26.7559 27.6518 25.8734 28.0255 25.5487L35.5199 31.4175V32.64H12.4799V31.3687L19.9649 25.545Z" fill="#44AD4E"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'MessageIcon'
}
</script>
