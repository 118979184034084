<template>
  <main class="main">
    <section class="welcome welcome__feedback">
      <div class="container">
        <div class="welcome__inner">
          <h1 class="welcome__title welcome__feedback__title">
            Задать вопрос
          </h1>
        </div>
      </div>
    </section>

    <section class="section container">
      <div class="donate__inner form__container">
        <div class="feedback__inputs">
          <div class="feedback__input feedback__question">
            <textarea @input="textIsNotValid = false" v-model="form.text" :class="{ error: textIsNotValid }" id="question" class="input"
                      placeholder="Опишите Ваш вопрос"></textarea>
            <span v-if="textIsNotValid" class="form__error__text">Введите текст</span>
          </div>
          <div class="feedback__input input__name">
            <input @input="fioIsNotValid = false" v-model="form.fio" :class="{ error: fioIsNotValid }" type="text" class="input" placeholder="ФИО"
                   maxlength="255">
            <span v-if="fioIsNotValid" class="form__error__text">Введите ФИО</span>
          </div>
          <div class="feedback__input input__phone">
            <input @input="phoneIsNotValid = false" v-model="form.phone" :class="{ error: phoneIsNotValid }" type="text" class="input"
                   placeholder="Номер телефона" v-mask="'+7 (###) ###-##-##'">
            <span v-if="phoneIsNotValid" class="form__error__text">Введите номер телефона</span>
          </div>
        </div>

        <button v-if="!loading" @click="submitFeedbackForm" type="button" class="feedback__btn btn">
          <MessageIcon/>
          <span>Отправить</span>
        </button>
        <button v-show="loading" type="button" class="feedback__btn btn">
          <Loader/>
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import MessageIcon from 'components/svg/Message.vue'
import Loader from 'components/Loader.vue'

export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_HOME_PAGE')
  },
  name: 'feedback',
  data () {
    return {
      form: {
        fio: '',
        phone: '',
        text: ''
      },
      loading: false,
      textIsNotValid: false,
      fioIsNotValid: false,
      phoneIsNotValid: false
    }
  },
  computed: {
    nav () {
      return this.$store.state.navigators.find(item => item.link === this.$route.path)
    }
  },
  methods: {
    submitFeedbackForm () {
      this.textIsNotValid = !this.form.text
      this.fioIsNotValid = !this.form.fio
      this.phoneIsNotValid = this.form.phone.length !== 18

      if (this.textIsNotValid || this.fioIsNotValid || this.phoneIsNotValid) {
        return 0
      }

      this.loading = true
      this.$store.dispatch('POST_FEEDBACK', {
        fio: this.form.fio,
        phone: this.form.phone,
        text: this.form.text
      })
        .then(response => {
          this.$store.state.postAnswer.value = 'Ваш вопрос отправлен!'
          this.$store.state.postAnswer.success = true
          Object.keys(this.form).forEach(item => {
            this.form[item] = ''
          })
          this.loading = false
          setTimeout(() => {
            this.$store.state.postAnswer.value = ''
          }, 2000)
        })
        .catch(err => {
          this.$store.state.postAnswer.value = 'Произошла ошибка!'
          this.$store.state.postAnswer.success = false
          this.loading = false
          setTimeout(() => {
            this.$store.state.postAnswer.value = ''
          }, 2000)
          console.log(err)
        })
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      this.nav.meta_title,
      this.nav.meta_keywords,
      this.nav.meta_description,
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    MessageIcon,
    Loader
  }
}
</script>
